import Swiper, { Pagination, Autoplay } from "swiper";
Swiper.use([Pagination, Autoplay]);

export default class setSwiper {
    constructor() {}

    set() {
        const swiper = new Swiper(".swiper-container", {
            // Optional parameters
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 5000,
            },

            // If we need pagination
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
            },
        });
    }
}
