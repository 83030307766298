import Intro from "./intro";
import ScrollFunc from "./scrollfunc";
import Canvas from "./kv_fall/canvas";
import dragscroll from "dragscroll"; //スクロールバーを隠している箇所でドラッグでスクロールさせるためのライブラリ
export default class Main {
    constructor() {
        this.init();
    }

    init() {
        this.eventBind();
        this.acc();
        this.archiveInteraction();
        this.sidenav();

        //instances
        this.intro = new Intro();
        this.ScrollFunc = new ScrollFunc();
        this.canvas = new Canvas();
    }

    eventBind() {
        window.addEventListener("scroll", (e) => {
            this.onScroll(document.documentElement || document.body);
        });
    }

    onScroll($container) {
        const body = document.body;
        const $elm = document.querySelector(".js-mainch");
        const scrollTop = $container.scrollTop;

        const scrollLimit = window.innerWidth < 768 ? 10 : 300;

        if (scrollTop >= scrollLimit) {
            $elm.classList.remove("-onback");
            $elm.classList.add("-oncmt");
            body.classList.add("-oncmt");
        } else {
            body.classList.remove("-oncmt");
            if ($elm.classList.contains("-oncmt")) {
                $elm.classList.add("-onback");
            }
            $elm.classList.remove("-oncmt");
        }
    }

    setFixScreen() {
        const wh = window.innerHeight;
        document.documentElement.style.setProperty("--screenHeight", `${wh}px`);
    }

    setArchivePh() {
        //ひとまず使わないことになりそう
        const shuffle = ([...array]) => {
            for (let i = array.length - 1; i >= 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        const eparr = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
        const generated = shuffle(eparr);
        const $archivePh = document.querySelectorAll(".js-archive_ph");

        for (let i = 0; i < $archivePh.length; i++) {
            console.log(generated[i]);
            $archivePh[i].setAttribute("src", `/assets/img/top/modal/endcard/ph_${generated[i]}.jpg`);
        }
    }

    acc() {
        const $trigger = document.querySelectorAll(".js-acc__trigger");

        $trigger.forEach((el) => {
            const container = el.nextElementSibling;

            el.addEventListener("click", () => {
                container.classList.toggle("-acc_active");
                el.classList.toggle("-acc_active");
            });
        });
    }

    archiveInteraction() {
        //todo::debounceが必要？？
        const $elm = document.querySelector(".archive");
        const $more = document.querySelector(".archive__more");

        $elm.addEventListener("mousemove", (e) => {
            $more.style.setProperty("--x", `${e.clientX}px`);
            $more.style.setProperty("--y", `${e.clientY}px`);
        });

        $elm.addEventListener("mouseenter", (e) => {
            $more.classList.add("-active");
        });

        $elm.addEventListener("mouseleave", (e) => {
            $more.classList.remove("-active");
        });
    }

    sidenav() {
        const $trigger = document.querySelector(".l-sidenav__trigger");

        $trigger.addEventListener("click", (e) => {
            document.body.classList.toggle("-sidenav_active");
        });
    }
}
